import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "./i18n";
import VueSweetalert2 from 'vue-sweetalert2';
import VueSidebarMenu from 'vue-sidebar-menu';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import VeeValidate, { Validator } from 'vee-validate'
import { VueEditor } from "vue2-editor";
import Quill from 'quill'
import { StripePlugin } from '@vue-stripe/vue-stripe';

const stripeOptions = {
  pk: process.env.VUE_APP_VITE_STRIPE_PK,
  testMode: true, // Boolean. To override the insecure host warning
  stripeAccount: process.env.VUE_APP_VITE_STRIPE_ACC,
};

Vue.use(StripePlugin, stripeOptions);

import mitt from 'mitt'
export const emitter = mitt();

// moment
import moment from 'moment'

// style
import 'sweetalert2/dist/sweetalert2.min.css';
import 'vue-sidebar-menu/dist/vue-sidebar-menu.css'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'flag-icon-css/css/flag-icons.min.css';


// plugins
import NowUIKit from "./plugins/now-ui-kit";

// filters
Vue.filter('formatDate', function(value) {
  if (value) {
    return moment(String(value)).format('DD/MM/YYYY HH:mm')
  }
})

// filters
Vue.filter('formatDateNoTime', function(value) {
  if (value) {
    return moment(String(value)).format('DD/MM/YYYY')
  }
})
Vue.filter('nameFile', function(value){
  return value.split('.').slice(0, -1).join('.');
})
Vue.filter('extensionFile', function(value){
  return value.split('.').pop();
})

Vue.filter('formatDataRole', function(value) {
  if (value) {
    return '✔'
  }
    return '✖'
})
Vue.filter('formatRole', function(value) {
  console.log('FORMAT ROLE', value)
  if (value == 'analyst') {
    // return 'Analyst'
    return i18n.t("Roles.Analyst")
  }
  if (value == 'admin') {
    // return 'Admin'
    return i18n.t("Roles.Admin")
  }
  if (value == 'project_manager') {
    // return 'Project Manager'
    return i18n.t("Roles.ProjectManager")
  }
})
Vue.filter('formatInvitation', function(value) {
  if (value.is_complete) return 'Complete'
  else if (value.is_rejected) return 'Incomplete'
  else if (value.is_cancelled) return 'Cancelled'
  else return 'Pending'

})
Vue.filter('formatReason', function(value) {
  if(value.is_rejected) return 'Rejected'

})

Vue.filter('formatPercentage', function(value) {
  if(value) {
    var fixed = value.toFixed(2)
    return fixed + '%'
  }

})

Vue.config.productionTip = false;
Vue.use(NowUIKit);
Vue.use(VueEditor);
Vue.use(VueSweetalert2);
Vue.use(VueSidebarMenu);
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

Vue.use(VeeValidate, {
  // This is the default
  inject: true,
  // Important to name this something other than 'fields'
  fieldsBagName: 'veeFields',
  // This is not required but avoids possible naming conflicts
  errorBagName: 'veeErrors'
})

const dictionary = {
  en: {
    messages:{
      integer: 'You must enter a valid number',
      required: 'Obligatory field',
      email: 'You must enter a valid email',
      alpha_spaces: 'You must enter only text, invalid numbers'
    }
  },
  es: {
    messages: {
      integer: 'Debe introducir un número válido',
      required: 'Campo obligatorio',
      email: 'Debe introducir un email válido',
      alpha_spaces: 'Debe introducir solo texto, números no validos'
    }
  }
};

// Override and merge the dictionaries
Validator.localize(dictionary);

const validator = new Validator({ first_name: 'numeric' });

validator.extend('required_table', (value, [otherValue]) => {
  // do something and finally return an object like this one:
  console.log('value validator:', value)
  if(value == null || value == undefined || value == ''){
    console.log('value validator required:', value)
    return {
      valid: false, // or false
      data: {
        required: true // or false
      }
    };
  }
  else{

    return {
      valid: true, // or false
      data: {
        required: true // or false
      }
    };
  }

}, {
  computesRequired: true
});


validator.extend('kebab-case', (value, [otherValue]) => {
  // do something and finally return an object like this one:

  return /^([a-z][a-z0-9]*)(-[a-z0-9]+)*$/.test(value) && value === value.toLowerCase();
});
// validator.extend('numeric_positive', (value, [otherValue]) => {
//   // do something and finally return an object like this one:

//   if(parseFloat(value) >= 0 && (value[0] !== '-' || value[value.length - 1] !== '-')){
//     return {
//       valid: true, // or false

//     };
//   }
//   else{
//     console.log('value validator:', value)
//     return {
//       valid: false, // or false

//     };
//   }

// });

validator.localize('en'); // now this validator will generate messages in Spanish.

// Global variable for detecting changes of the selected organization
const orgSelected = Vue.observable({ orgSelected: {} });
Object.defineProperty(Vue.prototype, '$orgSelected', {
  get() { return orgSelected.orgSelected; },
  set(value) { orgSelected.orgSelected = value; }
});

// Global variable for detecting changes of the selected role
const roleSelected = Vue.observable({ roleSelected: {} });
Object.defineProperty(Vue.prototype, '$roleSelected', {
  get() { return roleSelected.roleSelected; },
  set(value) { roleSelected.roleSelected = value; }
});

// Global variable for detecting changes on the sidebar
const sidebarCollapsed = Vue.observable({ sidebarCollapsed: {} });
Object.defineProperty(Vue.prototype, '$sidebarCollapsed', {
  get() { return sidebarCollapsed.sidebarCollapsed; },
  set(value) { sidebarCollapsed.sidebarCollapsed = value; }
});

// Global variable for detecting changes of the selected project
const projectSelected = Vue.observable({ projectSelected: {} });
Object.defineProperty(Vue.prototype, '$projectSelected', {
  get() { return projectSelected.projectSelected; },
  set(value) { projectSelected.projectSelected = value; }
});

// Global variable for detecting changes of the selected project
const tokensValue = Vue.observable({ tokensValue: {} });
Object.defineProperty(Vue.prototype, '$tokensValue', {
  get() { return tokensValue.tokensValue; },
  set(value) { tokensValue.tokensValue = value; }
});
// Global variable for detecting changes of the selected user image
const profileImageGlobal = Vue.observable({ profileImageGlobal: {} });
Object.defineProperty(Vue.prototype, '$profileImageGlobal', {
  get() { return profileImageGlobal.profileImageGlobal; },
  set(value) { profileImageGlobal.profileImageGlobal = value; }
});
// Global variable for detecting changes of the selected project
const currentProjects = Vue.observable({ currentProjects: [] });
Object.defineProperty(Vue.prototype, '$currentProjects', {
  get() { return currentProjects.currentProjects; },
  set(value) { currentProjects.currentProjects = value; }
});

Vue.prototype.$getImgUrlSite = function(key) {
    // console.log(key)
    return `${process.env.VUE_APP_VITE_BUCKET_CDN}/site-images/${key}?withheaders=yes`
}

console.log('main.js')

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");

i18n.locale = "en"
